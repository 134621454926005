<template>
  <auth-layout>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
      class="full-width d-flex flex-column align-center"
      tag="div"
    >
      <v-progress-linear
        indeterminate
        :height="2"
        style="max-width: 500px; width: 100%"
        v-if="loading"
      />
      <v-card
        :max-width="550"
        width="100%"
        outlined
        rounded="lg"
        color="background"
      >
        <div class="pa-6">
          <v-card-title
            class="textPrimary--text font-weight-semibold text-h4"
            >{{ $t('vendor.login.title') }}</v-card-title
          >
          <v-form @submit.prevent="onSubmit" :disabled="loading">
            <v-card-text>
              <cz-form-field
                large
                required
                :label="$t('auth.email')"
                :icon-src="mdiEmailOutline"
              >
                <validation-provider
                  rules="required|email"
                  :name="$t('auth.email')"
                  v-slot="{ errors }"
                >
                  <cz-input
                    :value="email"
                    :error-messages="errors"
                    :placeholder="$t('auth.enterEmail')"
                    @input="onEmailChange"
                    hint="כתובת המייל איתה נרשמת לפורטל"
                    persistent-hint
                  >
                  </cz-input>
                </validation-provider>
              </cz-form-field>
              <cz-form-field
                large
                required
                :label="$t('auth.password')"
                :icon-src="mdiFormTextboxPassword"
              >
                <validation-provider
                  rules="required"
                  :name="$t('auth.password')"
                  v-slot="{ errors }"
                >
                  <cz-input
                    v-model="password"
                    type="password"
                    :error-messages="errors"
                    :placeholder="$t('auth.enterLoginPassword')"
                  />
                </validation-provider>
              </cz-form-field>
              <v-alert
                v-model="showErrorMessage"
                type="error"
                color="negative"
                transition="slide-x-transition"
                dismissible
                :close-icon="mdiClose"
                >{{ errorMessage }}</v-alert
              >
            </v-card-text>
            <v-card-actions>
              <div
                class="full-width d-flex align-center flex-column"
                style="row-gap: 20px"
              >
                <v-btn
                  color="button"
                  :dark="!invalid"
                  x-large
                  type="submit"
                  :loading="loading"
                  :disabled="invalid"
                  rounded
                  class="full-width font-weight-semibold"
                  >{{ $t('auth.login') }}</v-btn
                >
                <div class="d-flex justify-space-between full-width">
                  <cz-button
                    text
                    class="text-body-1 font-weight-medium"
                    :title="$t('auth.forgotPassword')"
                    :icon-src="mdiAccountLockOpenOutline"
                    color="textPrimary"
                    @click="
                      $router.push({
                        name: 'forgot-password'
                      })
                    "
                  />
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <cz-button
                        :title="$t('auth.signupWhere')"
                        color="textPrimary"
                        v-on="on"
                        v-bind="attrs"
                        class="text-body-1 font-weight-medium"
                        :icon-src="mdiInformationOutline"
                        text
                      />
                    </template>
                    <v-card :width="450">
                      <v-card-text class="text-pre-wrap">
                        {{ $t('auth.signupWhereInfo') }}
                        <a href="mailto://hello@corz.app">hello@corz.app</a>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </v-card-actions>
          </v-form>
          <div class="d-flex justify-center mt-4">
            <cz-button
              :title="$t('auth.termsAndPrivacyPolicy')"
              color="textPrimary"
              :icon-src="mdiShieldAccountOutline"
              text
              href="https://storage.googleapis.com/corz-files-public/terms.pdf"
              target="_blank"
            />
          </div>
        </div>
      </v-card>
    </validation-observer>
  </auth-layout>
</template>

<script>
import bg from '@/assets/background-pattern.jpg';
import AuthLayout from '@/layouts/AuthLayout.vue';
import { CzFormField, CzInput, CzButton } from '@/components';
import { mapActions } from 'vuex';
import {
  mdiEmailOutline,
  mdiSecurity,
  mdiFormTextboxPassword,
  mdiInformationOutline,
  mdiShieldAccountOutline,
  mdiAccountLockOpenOutline,
  mdiClose
} from '@mdi/js';
export default {
  name: 'VendorSignUp',
  components: {
    AuthLayout,
    CzFormField,
    CzInput,
    CzButton
  },
  data() {
    return {
      mdiEmailOutline,
      mdiShieldAccountOutline,
      mdiAccountLockOpenOutline,
      mdiInformationOutline,
      mdiSecurity,
      mdiFormTextboxPassword,
      mdiClose,
      bg,
      loading: false,
      email: '',
      password: '',
      showErrorMessage: false,
      errorMessage: ''
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    onSignup() {
      this.$router.push({
        name: 'signup'
      });
    },
    async onSubmit() {
      this.loading = true;
      setTimeout(async () => {
        this.doLogin();
      }, 500);
    },
    onEmailChange(value) {
      this.email = value.replace(/\s/, '').toLowerCase();
    },
    async doLogin() {
      try {
        const { user } = await this.login({
          email: this.email,
          password: this.password
        });
        // if user is not verified then we need to do verification now
        if (user.isVerified) {
          if (this.$route.query?.redirect) {
            this.$router.replace(this.$route.query.redirect);
          } else {
            this.$router.replace({
              name: 'home'
            });
          }
        } else {
          this.$router.replace({
            name: 'verification'
          });
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.showErrorMessage = true;
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    showErrorMessage: {
      handler(val) {
        if (!val) {
          this.errorMessage = '';
        }
      }
    }
  }
};
</script>
